@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html {
  overflow-x: hidden;
}

.App {
  background-color: #fff;
}

.topnav {
  overflow: hidden;
  position: fixed;
  z-index: 10000;
  width: 100vw;
}

.bg-purple{
  background-color: rgb(116, 31, 162);
}

.topnav #myLinks {
  display: none;
}

.topnav a {
  float: left;
  color: white;
  /* padding: 14px 16px; */
  text-decoration: none;
  font-size: 17px;
}

.topnav a.icon {
  float: right;
  padding: 10px;
}

.web-name{
  font-family: "Poppins";
  font-weight: 600;
  letter-spacing: 0.1em;
  color: white;
}

.phone-nav-link{
  padding: 0.5rem 1rem;
  font-size: 18px;
  list-style: none;
  text-align: center;
  background-color: rgba(116, 31, 162, 0.9);
}

.menu-icon{
  filter: invert(1);
}
.links a {
  text-decoration: none;
  color: white;
}

.links li:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: scale(1.02);
}

.links ul {
  display: flex;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.navbar {
  color: white;
  background-color: rgb(116, 31, 162);
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}

.links li {
  margin: 0 1rem;
  border-radius: 999px;
  list-style: none;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.nav_buttons {
  padding: 0.8rem 1rem;
  margin: 0 0.5rem;
  color: white;
  background: none;
  border: none;
  font-weight: bold;
  border-radius: 9999px;
  transition: all 0.2s ease-in-out;
}

.main-image {
  width: 80vw;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}

.main-image img {
  border-radius: 30px;
  margin-top: 2rem;
  width: 100%;
  height: 380px;
}

.nav_buttons:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.search {
  background: white;
  padding: 0.6rem;
  border-radius: 34px;
}

.search_input {
  background: transparent;
  border: none;
  outline: none;
}

.search_input:active {
  outline: none;
}

.search_icon {
  padding: 0 0.5rem;
  font-family: inherit;
  background: transparent;
}

.comission-text {
  font-size: 13px;
}

.coupon {
  position: relative;
  width: 30%;
  cursor: pointer;
}

.coupon-description {
  line-height: 1.25;
  font-weight: 600;
  font-size: 0.8rem;
  font-family: Montserrat-fallback, Segoe UI, Helvetica Neue, Helvetica, Roboto,
    sans-serif;
  letter-spacing: 1.5px;
}

.coupon-img img {
  border-radius: 12px;
}

.shop-now {
  text-underline-offset: 4px;
  text-decoration: underline;
  letter-spacing: 0.1em;
  font-size: 0.865rem;
  overflow: hidden;
  line-height: 1rem;
  font-weight: bold;
  height: 20px;
}

.coupon-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  margin-right: 1rem;
}

.cashback_img_container {
  border: 0 solid #e5e7eb;
  /* background: red; */
  padding: 1rem;
  border-radius: 50%;
  height: 140px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in;
}

.cashback_img_container img {
  /* border-radius: 50%; */
  width: 120px;
  height: 120px;
}

.cashback_img_container:hover {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.count_down {
  font-size: 3rem;
  line-height: 1;
  text-shadow: -3px 3px #000, 1px 1px #000, -1px 1px #000, 1px -1px #000,
    -1px -1px #000;
  color: rgb(54 193 210 / 1);
  font-weight: 900;
}

.lets_go {
  font-size: 1.25rem;
  /* line-height: 1.75rem; */
  height: 30px;
}

.savecation-container {
  border: 1px solid black;
  border-radius: 20px;
}

.saving_img {
  margin-right: 1rem;
}

.coupon_img {
  object-fit: fill !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.073);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: contain;
  min-height: 120px;
}

.coupon_company {
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.85rem;
  line-height: 1rem;
  margin: 0;
  color: black;
}

.coupon-desc {
  color: black;
  text-transform: capitalize;
  line-height: 1.25rem;
  font-size: 1rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
}

.coupon {
  margin: 1rem 1rem;
  margin-left: 0;
  width: 19%;
  height: 290px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.175);
}

.coupon-code a {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0.5rem;
  color: rgb(116 31 162/1);
  bottom: 0;
  letter-spacing: 0.025em;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1rem;
  text-transform: uppercase;
}

.small-faded {
  color: rgb(107 114 128 / 1);
  letter-spacing: 0.1em;
  font-size: 10px;
  font-weight: 500;
}

.real-deal-container {
  background-color: #36c1d214;
}

.main-img img {
  border-radius: 40px;
}

.real-deal-side-img {
  object-fit: cover;
  border-radius: 1rem;
  width: 9rem;
  height: 8rem;
}

.real-deal-side-card-head {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 0.25rem !important;
}

.text-gray {
  color: rgb(75 85 99/1);
}

.sponsored {
  transform: translateY(-25px);
  font-size: 10px;

  letter-spacing: 0.1em;
  font-weight: 500;
}

.category {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
  cursor: pointer;
}

.open-close-btn {
  font-size: 30px;
}

.ordered-head {
  cursor: pointer;
}

.logo img {
  padding: 0.7rem;
  border-radius: 25px;
  width: 110px;
  /* border-radius: 20%; */
}

.footer {
  background-color: rgb(116, 31, 162);
  width: 100%;
  color: white;
}

.footer-data {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
  width: 25%;
}

.footer-data div {
  padding: 0.5rem 0;
}

.qr-code {
  width: 100px;
  margin-right: 1rem;
}

.hover_underline:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 0.4px;
  cursor: pointer;
}

.slick-next {
  right: 10% !important;
  height: 50px !important;
  width: 50px !important;
  transform: none !important;
}

.slick-next::before,
.slick-prev::before {
  font-size: 40px !important;
}

.slick-prev {
  left: 10% !important;
  z-index: 10;
  transform: none !important;
}

.section_all {
  position: relative;
  padding-top: 0;
  padding-bottom: 80px;
  min-height: 100vh;
}
.section-title {
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.section-subtitle {
  letter-spacing: 0.4px;
  line-height: 28px;
  max-width: 550px;
}

.section-title-border {
  background-color: #000;
  height: 1 3px;
  width: 44px;
}

.section-title-border-white {
  background-color: #fff;
  height: 2px;
  width: 100px;
}
.text_custom {
  color: #00bd2a;
}

.about_icon {
  width: max-content;
  padding: 1rem;
  margin: auto;
  border-radius: 50%;
  /* font-size: 22px;
  height: 65px;
  width: 65px;
  line-height: 65px;
  display: inline-block;
  background: #fff;
  border-radius: 35px;
  color: #00bd2a; */
  box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
}

.about_header_main .about_heading {
  max-width: 450px;
  font-size: 24px;
}

.about_icon span {
  position: relative;
  top: -10px;
}

.about_content_box_all {
  padding: 28px;
}

.about_icon img {
  height: 30px;
}

.img_about img {
  border-radius: 18px;
}

.welcome_banner {
  padding-bottom: 80px;
  max-height: 500px;
  height: auto;
  width: 100vw;
}

a {
  text-decoration: none;
  color: white;
}

.offer-image {
  width: 90%;
  height: auto;
}

.overlayText {
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0.5rem;
  background: black;
  color: white;
  padding: 0.4rem;
  border-radius: 12px;
}

.coupon-content-container {
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company-logo-container {
  padding: 0.5rem 0.5rem;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

.company-coupon-logo {
  /* border: 1px solid black; */
  padding: 1rem;
  aspect-ratio: 1 / 1;
  width: 50%;
  height: auto;
  border-radius: 30px;
}

.coupon-display-box {
  background: white;
  padding: 2rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 80%;
}

.coupon-company {
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: 600;
}

.coupon-offering {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  text-align: center;
}

.coupon-box {
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  padding: 0.2rem;
  border-radius: 40px;
  align-items: center;
}

.coupon-box > .coupon-code-box {
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: black;
  margin: 0 1rem;
  font-weight: 600;
}

.coupon-box > .copy-btn {
  color: white;
  outline: none;
  border: none;
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-transform: uppercase;
  font-weight: 700;
  background-color: rgb(116 31 162);
  padding: 0.5rem 1rem;
  letter-spacing: 0.1em;
  border-radius: 40px;
}

.small-desc {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: center;
}

.ends {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-align: center;
}
.coupon-display-bottom {
  background-color: rgb(243 244 246);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 80%;
}

.more {
  letter-spacing: 0.05em;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}
.coupon-container {
  overflow-y: scroll;
  /* position: relative; */
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  left: 0;
  position: fixed;
}

.coupon-content-container {
  /* width: 60%; */
  /* margin: auto; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* background-color: rgba(185, 185, 185, 0.5); Background with opacity */
  /* backdrop-filter: blur(10px); Apply blur effect */
  border-radius: 15px;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: bold;
}

.header-img-container img {
  height: 340px;
  width: 100vw;
}

.rounded-img {
  border-radius: 12px;
}

.email-icon{
  width: 150px !important;
}