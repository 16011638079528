@font-face {
    font-display: swap;
    font-family: proxima-nova;
    font-style: normal;
    font-weight: 500;
    src: local("Proxima Nova"),url(https://use.typekit.net/af/0758f3/000000000000000077359583/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("woff2"),url(https://use.typekit.net/af/0758f3/000000000000000077359583/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("woff"),url(https://use.typekit.net/af/0758f3/000000000000000077359583/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("opentype")
}

@font-face {
    font-display: swap;
    font-family: proxima-nova;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    src: local("Proxima Nova"),url(https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3) format("woff2"),url(https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3) format("woff"),url(https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3) format("opentype")
}

@font-face {
    font-family: Proxima-fallback;
    size-adjust:98%;src: local("Arial")
}

@font-face {
    ascent-override:82%;font-family: Montserrat-fallback;
    size-adjust:111.39999999999998%;src: local("Arial")
}

@font-face {
    font-family: Montserrat-bold-fallback;
    size-adjust:113%;src: local("Verdana")
}