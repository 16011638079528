@media only screen and (max-width: 1024px) {
  .main-image img {
    height: 240px;
  }

  .resp-flex-column {
    flex-direction: column;
  }

  .resp-flex-wrap {
    flex-wrap: wrap;
  }

  .resp-w-40p {
    width: 40% !important;
  }

  .coupon {
    width: 30%;
  }

  .resp-justify-evenly {
    justify-content: space-evenly !important;
  }

  .email-icon{
    width: 70px !important;
  }
}

@media only screen and (max-width: 780px) {
  .box_4,
  .box_5 {
    display: none !important;
  }

  .footer-box {
    margin: auto !important;
  }

  .slick-track {
    width: max-content !important;
  }

  .footer-data {
    width: 45%;
  }

  .resp-w-100p {
    width: 100% !important;
  }

  .resp-align-center {
    align-items: center;
  }

  .resp-flex {
    display: flex;
  }

  .resp-flex-column-min {
    flex-direction: column;
  }

  .resp-flex-wrap-780 {
    flex-wrap: wrap;
  }

  /* .cashback_img_container img{
        width: 80px;
        height: 80px;
    }

    .cashback_img_container{
        width: 100px;
        height: 100px;
    } */

  .resp-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .offer-image {
    width: 70%;
  }
  .coupon {
    width: 40%;
  }

  .resp-justify-evenly {
    justify-content: space-evenly !important;
  }

  .w-80p {
    width: 90% !important;
  }

  .resp-my-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #about {
    padding-top: 5rem;
  }
  .carousel-container {
    padding-top: 2.9rem;
  }
  .contact-container {
    padding-top: 2rem;
  }
  .brand-container {
    padding-top: 5rem;
  }
}
@media only screen and (max-width: 610px) {
  .header-img-container img {
    height: 200px !important;
  }
  .small-flex-column {
    flex-direction: column;
  }

  .small-w-80 {
    width: 80% !important;
  }

  .small-align-center {
    align-items: center;
  }
  .footer-data {
    font-size: 14px;
  }
  .brand-data-container {
    flex-direction: column;
    align-items: center;
  }

  .products-container {
    justify-content: center;
  }

  .brand-data-container .w-50p {
    width: 100%;
  }

  .cashback_img_container {
    width: 110px;
    height: 110px;
    padding: 0;
  }
  .cashback_img_container img {
    width: 100%;
    height: 100%;
  }
  .cash-back {
    font-size: 16px;
  }
  .cash-back-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .resp-p-0 {
    padding: 0 !important;
  }
  .resp-mt-0 {
    margin-top: 0 !important;
  }
  .margin-top-lil {
    margin-top: 0.4rem !important;
  }
  .box_3 {
    display: none !important;
  }
}

@media only screen and (max-width: 430px) {
  .cashback_img_container {
    width: 80px;
    height: 80px;
    padding: 0;
  }
  .cash-back{
    font-size: 13px;
  }
  .category {
    font-size: 14px;
  }
  .box_1,
  .box_2 {
    padding: 0 0.3rem;
  }
  .phone-view {
    width: 80vw;
    border-top: 3px solid rgba(0, 0, 0, 0.388);
    margin: auto;
    display: block !important;
  }
  .header-img-container img {
    height: 160px !important;
  }
  .flex-column-min {
    flex-direction: column;
  }
  .brand-container {
    padding-top: 3rem;
  }
  .coupon {
    width: 100%;
    height: auto;
    margin: 1rem 0;
  }
  .footer-data {
    text-align: center;
    width: 90%;
    margin: auto;
  }
  .resp-w-100p {
    width: 100% !important;
  }

  .offer-image {
    width: 100%;
  }

  .resp-w-95vw {
    width: 95vw;
  }

  .menu-icon {
    height: 30px !important;
  }

  .logo img {
    padding: 0;
    width: 90px;
    border-radius: 0;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .main-image img {
    height: 320px;
    width: 100vw;
    object-fit: cover;
    border-radius: 0;
  }

  .main-image {
    width: 100vw;
  }

  .coupon_img {
    width: 40%;
    border-radius: 8px;
  }

  /* Adjust width for Firefox */
  @-moz-document url-prefix() {
    .coupon_img {
      width: 140px;
    }
  }
  .coupon-code {
    margin-top: 0.5rem;
  }
  .coupon-code a {
    margin: 0;
    position: relative;
  }

  .coupon {
    border: none;
  }

  .coupon-content-container {
    width: 100%;
  }
  .coupon-display-bottom {
    width: 90%;
  }
  .coupon-display-box {
    width: 90%;
  }

  .resp-mt-1r {
    margin-top: 1rem !important;
  }

  .resp-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .resp-mx-05 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .resp-justify-between {
    justify-content: space-between !important;
  }
  .company-coupon-logo{
    aspect-ratio: 1.5/1;
    width: 100%;
  }
}

@media only screen and (max-width: 330px) {
  .cashback_img_container {
    width: 70px;
    height: 70px;
  }
  .cashback_img_container img {
    width: 70px;
    height: 70px;
  }
  .margin-top-lil {
    margin-top: 0 !important;
  }
  .h-20 {
    height: 15px;
  }
  .cash-back-text-container {
    margin-top: 0.4rem;
  }
  .cash-back {
    font-size: 11px;
  }
  .brand-desc {
    font-size: 14px;
  }
  .logo img {
    padding: 0;
    width: 60px;
    border-radius: 0;
  }
  .web-name {
    font-size: 12px;
  }

  .menu-icon {
    height: 25px !important;
  }

  .saving_img {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .saving_card {
    flex-direction: column;
  }

  .saving_img img {
    height: 55px;
  }

  .contact-info-item {
    justify-content: center;
    align-items: center;
  }
  .contact-info-icon {
    height: 50px !important;
    width: 50px !important;
  }
  .contact-info-icon img {
    height: 25px !important;
    width: 25px !important;
  }
  .section-header h2 {
    font-size: 2em !important;
  }
  .contact-info {
    display: flex;
    flex-direction: column;
    width: 90% !important;
    margin: auto;
    align-items: baseline;
  }

  .section-header p {
    padding: 0 1rem;
    font-size: 13px;
  }
}
