.font-poppins {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.fw-bold {
  font-weight: bold;
}
.fw-normal {
  font-weight: normal;
}
.fw-500 {
  font-weight: 500;
}

.color-white {
  color: white;
}

.w-fit-content {
  width: fit-content;
}

.w-15p {
  width: 15%;
}
.w-20p {
  width: 20%;
}
.w-25p {
  width: 25%;
}
.w-40p {
  width: 40%;
}
.w-45p {
  width: 45%;
}
.w-30p {
  width: 30%;
}
.w-35p {
  width: 35%;
}
.w-50p {
  width: 50%;
}
.w-55p {
  width: 55%;
}
.w-60p {
  width: 60%;
}
.w-70p {
  width: 70%;
}
.w-80p {
  width: 80%;
}
.w-80vw {
  width: 80vw;
}
.w-90p {
  width: 90%;
}

.mt-10rem {
  margin-top: 10rem;
}
.mt-8rem {
  margin-top: 8rem;
}

.mb-20px {
  margin-bottom: 20px;
}

.mb-5rem {
  margin-bottom: 5rem;
}

.mx-7 {
  margin-left: 5rem;
  margin-right: 5rem;
}
.my-6 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.ml-5rem {
  margin-left: 5rem;
}
.mr-5rem {
  margin-right: 5rem;
}
.mt-5rem {
  margin-top: 5rem;
}
.mr-2rem {
  margin-right: 2rem;
}
.mr-4rem {
  margin-right: 4rem;
}
.mb-2rem {
  margin-bottom: 2rem;
}
.mb-5rem {
  margin-bottom: 5rem;
}

.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}

.no-overflow {
  overflow: hidden;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.mt-5rem {
  margin-top: 5rem;
}

.mx-5rem {
  margin-left: 5rem;
  margin-right: 5rem;
}

.overflow-initial {
  overflow: initial;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.top-auto {
  top: auto;
}

.r-10p {
  right: 10%;
}

.fz-3r {
  font-size: 3rem;
}

.objf-none {
  object-fit: none;
}

.h-100vh {
  height: 100vh;
}

.h-20px {
  height: 20px;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.color-white {
  color: white !important;
}

.fz-35px {
  font-size: 35px;
}

.fz-20px {
  font-size: 20px;
  font-size: 20px;
}

.lh-120 {
  line-height: 120px;
}

.border-1px-w {
  border: 1px solid white !important;
}

.overflow-visible {
  overflow-x: visible;
}

.z-10{
  z-index: 10;
}

.z-1{
  z-index: 1;
}

.underline{
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 0.4px;
  cursor: pointer;
}